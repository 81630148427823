import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  constructor() {
    super()
  }

  async handleError(error: any): Promise<void> {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    // esta rotina serve para interceptar o erro acima, que provavelmente eh decorrente
    // do service-worker se perder e ficar com 2 modulos com versões incompatives no browser
    if (chunkFailedMessage.test(error.message)) {
      console.log('vai reiniciar devido a erro service-worker com versoes de modulos')
      window.location.reload();
    } else {
      if (error.message && typeof error.message == 'string') {
        let _errorMessage = error.message as string;
        // console.log(_errorMessage)
        if (_errorMessage.includes('Persistence can only')) {
          // erro ao limpar cache firebase vai continuar normal
          return;
        } else {
          super.handleError(error)
        }
      } else {
        super.handleError(error)
      }
    }
    // other stuff for error handling.
  }
}
