import { BaseModelBlog } from './baseModelBlog';

export class UsuarioBlogModel extends BaseModelBlog {
  nmUsuario: string;
  edEmailUsuario: string;
  nuCPF: string;
  cdDDITelefone: number;
  nuTelefone: string;
  tpUsuario: number;
  dlFotoUsuario: string;
  dtNascimento: string;
  cdGenero: string;
  dlSenhaUsuario: string;
  dlTokenFCM?: string;
  flAdminFinanceiro: number;
  flUsuarioTeste: number;
  campanha?: any;                    // não interessa no escopo do blog
  dlConfirmacaoSenha?: string;
  flEmailConfirmado?: boolean;
  dhUltimoLoginAPP: string;
  dsDeviceUltimoLoginAPP: string;
  dsVersaoUltimoLoginAPP: string;
  dhUltimoLoginEmpresas: string;
  dsDeviceUltimoLoginEmpresas: string;
  dsVersaoUltimoLoginEmpresas: string;
  dsOrigem: string;
  dsComplementoOrigem: string;

  constructor() {
    super();
  }

}
