import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
// import { FileSaverService } from 'ngx-filesaver';

import { environment } from '../../environments/environment';
import { FuncoesGeraisBlogService } from './funcoesgerais-blog.service';
import { IResultHttpBlog } from '../interfaces/IResultHttpBlog';

@Injectable({
  providedIn: 'root'
})
export class HttpBlogService {

  constructor(
    private http: HttpClient,
    private funcoesGeraisBlogSrv: FuncoesGeraisBlogService,
    // private fileSaverSrv: FileSaverService,
    private matSnack: MatSnackBar,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {

  }

  createHeader(header?: HttpHeaders, flUploadFile?: boolean): HttpHeaders {
    if (!header) {
      header = new HttpHeaders();
    }
    // as chamadas normais envia o header Content-Type, exceto no upload de arquivos
    if (!flUploadFile) {
      header = header.append('Content-Type', 'application/json');
    }
    header = header.append('Accept', 'application/json');
    const token = localStorage.getItem('PasseioJaBlog:token');
    if (token) {
      header = header.append('x-token-access', token);
    }
    header = header.append('version', environment.version);
    header = header.append('device', 'geren');
    return header;
  }

  public get(url: string, flNaoUsarSpinner?: number): Promise<IResultHttpBlog> {
    const header = this.createHeader();
    return new Promise(async (resolve) => {
      try {
        if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
          this.spinner.show();
        }
        const res = await this.http.get(url, { headers: header }).toPromise();
        if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
          this.spinner.hide();
        }
        if (res) {
          resolve({ success: true, data: res, error: undefined });
        }
      } catch (error: any) {
        if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
          this.spinner.hide();
        }
        if (error?.status === 400) {
          // se não deu token expirado retorna o erro,
          // caso expirado, direciona para o login
          const _retExpirado = await this.tokenExpirado(error);
          if (_retExpirado && _retExpirado == 'N') {
            resolve({ success: false, data: {}, error });
          }
        } else {
          error = await this.substituirPorErroPadrao(error)
          resolve({ success: false, data: {}, error });
        }
      }
    });
  }

  public post(url: string, model: any, headers?: HttpHeaders, flNaoUsarSpinner?: number): Promise<IResultHttpBlog> {
    const header = this.createHeader(headers);
    return new Promise(async (resolve) => {
      try {
        if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
          this.spinner.show();
        }
        const res = await this.http.post(url, model, { headers: header }).toPromise();
        if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
          this.spinner.hide();
        }
        if (res) {
          resolve({ success: true, data: res, error: undefined });
        }
      } catch (error: any) {
        if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
          this.spinner.hide();
        }
        // console.log(error)
        if (error.status === 400) {
          // se não deu token expirado apresenta os erros,
          // caso expirado, direciona para o login
          const _retExpirado = await this.tokenExpirado(error);
          if (_retExpirado && _retExpirado == 'N') {
            // console.log(error)
            let retorno = { success: false, data: {}, error }
            this.funcoesGeraisBlogSrv.MostraErros(retorno);
            resolve({ success: false, data: {}, error });
          }
        } else {
          error = await this.substituirPorErroPadrao(error)
          let retorno = { success: false, data: {}, error }
          this.funcoesGeraisBlogSrv.MostraErros(retorno);
          resolve({ success: false, data: {}, error });
        }
      }
    });
  }

  // Rota especial para upload de arquivo diferente de imagem
  // cujo model vai no formato FormaData (com enctype próprio para envio de file)
  public uploadFile(url: string, model: any, flNaoUsarSpinner?: number, headers?: HttpHeaders, msgSpinner?: string): Promise<IResultHttpBlog> {
    const _flUploadFile = true;
    // flag _flUploadFile serve para não enviar no header o Content-Type', 'application/json
    const header = this.createHeader(headers, _flUploadFile);
    return new Promise(async (resolve) => {
      try {
        if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
          await this.spinner.show(msgSpinner ? msgSpinner : 'Enviando arquivo...');
        }
        const res = await this.http.post(url, model, { headers: header }).toPromise();
        if (res) {
          if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
            this.spinner.hide();
          }
          resolve({ success: true, data: res, error: undefined });
        }
      } catch (error: any) {
        if (!flNaoUsarSpinner || flNaoUsarSpinner == 0) {
          this.spinner.hide();
        }
        if (error.status === 400) {
          // se não deu token expirado apresenta os erros,
          // caso expirado, direciona para o login
          const _retExpirado = await this.tokenExpirado(error);
          if (_retExpirado && _retExpirado == 'N') {
            // situaçoes especiais a primeira msg vem iniciando com '999999' para tratamento especial no APP
            // e não mostra erros aqui
            // por exemplo: vide retorno da confirmação de pedido
            if (Array.isArray(error.error)
              && error.error[0].message.substring(0, 6) == '999999') {
              resolve({ success: false, data: {}, error });
            } else {
              resolve({ success: false, data: {}, error });
            }
          }
        } else {
          error = await this.substituirPorErroPadrao(error)
          resolve({ success: false, data: {}, error });
        }
      }
    });
  }

  public delete(url: string): Promise<IResultHttpBlog> {
    const header = this.createHeader();
    return new Promise(async (resolve) => {
      try {
        this.spinner.show();
        const res = await this.http.delete(url, { headers: header }).toPromise();
        if (res) {
          this.spinner.hide();
          resolve({ success: true, data: res, error: undefined });
        }
      } catch (error: any) {
        this.spinner.hide();
        if (error.status === 400) {
          // se não deu token expirado apresenta os erros,
          // caso expirado, direciona para o login
          const _retExpirado = await this.tokenExpirado(error);
          if (_retExpirado && _retExpirado == 'N') {
            resolve({ success: false, data: {}, error });
          }
        } else {
          error = await this.substituirPorErroPadrao(error)
          resolve({ success: false, data: {}, error });
        }
      }
    });
  }

  // caso retorne token expirado da API, direciona para o login
  async tokenExpirado(error: any): Promise<string> {
    let _textoMsg: string = '';
    if (Array.isArray(error.error)) {
      _textoMsg = error.error[0].message;
    } else {
      _textoMsg = error.message;
    }
    if (_textoMsg == 'Token expirado') {
      localStorage.removeItem('PasseioJaBlog:token');
      this.matSnack.open('Login expirado', undefined, { duration: 3000 });
      setTimeout((a: any) => {
        if (!this.router.url.includes('registraToken')) {
          window.location.reload();
          return 'S';
        } else {
          this.router.navigateByUrl('');
          return 'S';
        }
      }, 3000, []);
    } else {
      // console.log('vai voltar tokenExpirado false')
      return 'N';
    }
  }

  // usada quando erro.status diferente de 400
  substituirPorErroPadrao(error: any) {
    if (Array.isArray(error)) {
      let _itemErro: any;
      if (error.length == 1) {
        _itemErro = error[0];
        _itemErro.message = 'Ocorreu um erro inesperado ao acessar o servidor.' +
          ' Favor verificar sua internet e tentar novamente'
        error.splice(0, 1, _itemErro);
      } else {
        _itemErro = error[0];
        _itemErro.message = 'Ocorreu um erro inesperado ao acessar o servidor.'
        error.splice(0, 1, _itemErro);
        _itemErro = error[1];
        _itemErro.message = 'Por favor, verifique sua internet e tente novamente.'
        error.splice(1, 1, _itemErro);
      }
    } else {
      // o retorno da API não vem como array quando ocorre um erro diferente
      // de status 400 (retornado pela aplicação)
      console.log('Erro na rotina substituirPorErroPadrao - ', error.message)
      error.message = 'Ocorreu um erro inesperado ao acessar o servidor.' +
        ' Por favor, verifique sua internet e tente novamente.'
    }
    return error;
  }

  // // usado para fazer download de arquivos de uma URL
  // // podendo ser .txt, .xlsx, .docx, .zip,...
  // // quando baixado no browser - usando pacote ngx-filesaver/file-saver
  // async downloadURL(url: string, nmArquivoDestino: string, flNaoUsarSpinner?: number) {
  //   try {
  //     // console.log(url);
  //     this.getFile(url)
  //       .subscribe(blob =>
  //         this.fileSaverSrv.save(blob, nmArquivoDestino))
  //         // importedSaveAs(blob, nmArquivoDestino))
  //   } catch (error) {
  //     this.funcoesGeraisBlogSrv.MostraErros('', `Não foi possível baixar o arquivo`);
  //     console.log('Erro ao baixar o arquivo', error)
  //     return 'Erro';
  //   }
  // }

  // faz o get no servidor, retornando observable
  getFile(url: string): Observable<Blob> {
    const header = this.createHeader();
    return this.http.get(url, {
      responseType: 'blob',
      headers: header
    })
  }

}
