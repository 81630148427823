export const ConstantsBlog = {
  confirm_excl_swal_options: {
    showCancelButton: true,
    confirmButtonColor: '#673ab7',
    cancelButtonColor: '#9e9a9b',
    confirmButtonText: 'Sim, excluir!',
    cancelButtonText: 'Cancelar',
    title: 'Excluir?'
  },

  confirm_swal_options: {
    showCancelButton: true,
    confirmButtonColor: '#673ab7',
    cancelButtonColor: '#9e9a9b',
    confirmButtonText: 'Sim',
    cancelButtonText: 'Cancelar',
    title: 'Confirme'
  },

  ok_swal_options: {
    showCancelButton: false,
    confirmButtonColor: '#673ab7',
    confirmButtonText: 'Ok',
  },
  // secretKeyCriptoJs - usada para criptografar (cripto-js) a query enviada do client para o servidor
  secretyKeyCriptoJs: '8b45-c13a07259ead-4bdc-3ecd13f9-87d2',

  // nmBD: 'bd_ea',

  arrayMoeda: [
    { sigla: 'BRL', simbolo: 'R$ ', milhar: '.', decimal: ',' },
    { sigla: 'EUR', simbolo: '€ ', milhar: ',', decimal: '.' },
    { sigla: 'USD', simbolo: '$ ', milhar: ',', decimal: '.' }],


};
