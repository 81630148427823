import { OnInit, OnDestroy, Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

// import { IMenu, IMenuItem } from './interfaces/IMenu';
import { environment } from 'src/environments/environment';
import { ScreensizeBlogService } from './services/screensize-blog.service';
import { FuncoesGeraisBlogService } from './services/funcoesgerais-blog.service';
import { IMenuItem } from './interfaces/IMenu';
import { UsuarioBlogService } from './services/usuario-blog.service';
import { SwupdaterService } from './services/swupdater.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isLogged: boolean = false;
  // menu: Array<IMenu> = new Array<IMenu>();
  subscriptions: Array<Subscription> = [];
  isDesktop: boolean = false;
  _heightSubcabec: number = 55;

  _urlPasseioJa: string = environment.urlPasseioJa;
  _urlApi: string = environment.url_api;
  _nmFantasiaAgencia: string = '';
  _nmUsuario: string = '';
  _dlFotoUsuario: string;
  _tpUsuario: number = null;
  _flGuiaAdministrador: boolean = false;
  _flInstitucional: boolean = true;     // flag indicando que está na parte institucional - não inclui o cabeçalho do geren

  _dsTituloPagina: string = '';
  _flPaginaTemRetorno: boolean = false;
  _flTemCabecPadrao: boolean = true;
  _arrayMenu: IMenuItem[] = [
    { label: "Passeios", url: `${environment.urlPasseioJa}`, icon: "", option: '_self' },
    { label: "Seja nosso parceiro!", url: `${environment.urlSiteGeren}`, icon: "", option: '_blank' },
    { label: "Entrar", url: `${environment.urlPasseioJa}/tabs/login`, icon: "", option: '_self' },
    { label: "Como Funciona", url: `${environment.urlPasseioJa}/tabs/tabAjuda/comoFunciona`, icon: "", option: '_self' },
  ]

  currentUrl: string = '';
  previousUrl: string = '';

  constructor(
    private location: Location,
    private screensizeBlogSrv: ScreensizeBlogService,
    private router: Router,
    private usuarioBlogSrv: UsuarioBlogService,
    private matSnack: MatSnackBar,
    private funcoesGeraisBlogSrv: FuncoesGeraisBlogService,
    public swupdaterSrv: SwupdaterService,
  ) {
    this.isDesktop = this.screensizeBlogSrv.registraWidth(this.router.url);
    this.subscriptions.push(this.screensizeBlogSrv.isDesktopView().subscribe(isDesktop => {
      this.isDesktop = isDesktop;
    }));
    this.funcoesGeraisBlogSrv.SetAppVersion();
    this.subscriptions.push(this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }));
    // Verifica se existe nova versão pelo Service Worker
    this.swupdaterSrv.checkForUpdates();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => { sub.unsubscribe(); });
  }

  ngOnInit() {
    this.subscriptions.push(this.funcoesGeraisBlogSrv.getTituloPaginaAtual().subscribe(dsTituloPagina => {
      this._dsTituloPagina = dsTituloPagina;
      if (this._dsTituloPagina.length > 30) {
        this._heightSubcabec = 70;
      } else {
        this._heightSubcabec = 55;
      }
    }));
    this.subscriptions.push(this.funcoesGeraisBlogSrv.getPaginaTemRetorno().subscribe(flPaginaTemRetorno => {
      this._flPaginaTemRetorno = flPaginaTemRetorno;
    }));
    this.subscriptions.push(this.funcoesGeraisBlogSrv.getTemCabecPadrao().subscribe(flTemCabecPadrao => {
      this._flTemCabecPadrao = flTemCabecPadrao;
    }));
    this.TratarUsuarioLogado();
  }

  // rotina para forçar a avaliação do tamanho da tela quando o usuário mudar o layout
  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    this.screensizeBlogSrv.onResize(event.target.innerWidth);
  }

  TratarUsuarioLogado() {
    // Necessário subscribe do nome para o caso do usuário mudar seu nome e refletir na página automaticamente
    this.subscriptions.push(this.usuarioBlogSrv.observableNomeUsuarioLogged().subscribe(nmUsuLogged => {
      this._nmUsuario = nmUsuLogged;
      this._tpUsuario = this.usuarioBlogSrv.usuario.tpUsuario;
      if (this._tpUsuario == 3) {
        this._arrayMenu.push(
          {
            label: "Criar Post",
            url: `/admin/postEdit/new`,
            icon: "",
            option: 'proprio_site'
          },
        )
      }
      // Monta nome do usuario para página apenas com primeiro e segundo nome
      let _arrayPedaco = this._nmUsuario.split(' ');
      this._nmUsuario = _arrayPedaco[0];
      if (_arrayPedaco.length > 1 && this._tpUsuario != 3) {
        this._nmUsuario = this._nmUsuario + ' ' + _arrayPedaco[1];
      }
    }));
    this.subscriptions.push(this.usuarioBlogSrv.observableFotoUsuarioLogged().subscribe(dlFotoLogged => {
      this._dlFotoUsuario = dlFotoLogged;
    }));
  }

  async updateApp() {
    this.matSnack.open('O site será reiniciado devido a nova versão', undefined, { duration: 3000 });
    setTimeout(async (a: any) => { document.location.reload(); }, 3000, []);
  }

  CriarPost() {
    setTimeout((a: any) => {
      this.router.navigateByUrl(`/admin/postEdit/new`);
    }, 100, []);
  }

  Retornar() {
    if (this.previousUrl) {
      this.location.back();
    } else {

      this.router.navigateByUrl('/');
    }
  }

  Navegar(url: string, option: string) {
    if (option == 'proprio_site') {
      this.router.navigateByUrl(url);
    } else {   // navegando para o site principal ou empresas
      window.open(url, option);
    }
  }

  // SiteInstitucional() {
  //   window.open(environment.urlSiteGeren, '_blank');
  // }

}
