import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardBlogService } from './services/auth-guard-blog.service';

// import { AdminGuardService } from './services/adminguard.service';

// a página institucional vai ficar numa rota alternativa
// atenção para a sintaxe para chamar uma rota em outlet alternativa:
//     /(<outlet-custom-name>:<path-name>)
// Obs.: os componentes estão no diretorio modules/institucional,
//       porem nao existe um modulo institucional
const routes: Routes = [
  {
    path: '',
    redirectTo: 'posts/categoria/todas/tag/todas/pagina/1',
    pathMatch: 'full'
  },
  {
    path: 'posts',
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
  },
  // rota postsmobile não existem na pratica, servem apenas para fazer o prerender para mobile.
  // Posteriormente ao prerender as paginas estaticas de /postsmobile são copiadas para outro
  // diretorio raiz especifico para Bot com acesso mobile para ser servido pelo nginx.
  // No entanto, neste outro diretório as pastas /postsmobile ficam como apenas /posts
  {
    path: 'postsmobile',
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuardBlogService],
    data: { roles: ['admin'] }
  },
  {
    path: '**',
    redirectTo: 'posts/categoria/todas/tag/todas/pagina/1',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

