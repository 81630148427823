import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isScullyRunning } from '@scullyio/ng-lib';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreensizeBlogService {

  private isDesktop = new BehaviorSubject<boolean>(false);
  private width = new BehaviorSubject<number>(0);
  public _width: number = 0;

  constructor(
    private router: Router,
  ) { }

  registraWidth(url?: string): boolean {
    let _isDesktop: boolean = false;
    // a rota soh vai iniciar com '/tabsmobile' durante o prerender
    // pois a execução normal soh inicia como '/tabs'
    // no caso vai criar o prerender no diretorio separado (forcando o formato do celular)
    // para depois ser servido pelo nginx conforme o device usado pelo bot
    if (url && url.startsWith('/postsmobile')) {
      _isDesktop = false;
      this._width = 360;
      this.width.next(360);
    } else {
      let _width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
      if (_width && _width > 0) {
        if (_width < 768) {
          _isDesktop = false;
        } else {
          _isDesktop = true;
        }
        if (isScullyRunning()) {
          this._width = 1280;
          this.width.next(1280);
        } else {
          this._width = _width;
          this.width.next(_width);
        }
      } else {
        _isDesktop = true;
        this._width = 1440;
        this.width.next(1440);
      }
    }
    this.isDesktop.next(_isDesktop);
    return _isDesktop;
  }

  onResize(size: number) {
    this._width = size;
    this.width.next(size);
    if (size < 768) {
      this.isDesktop.next(false);
    } else {
      this.isDesktop.next(true);
    }
  }

  Reload() {
    // console.log('vai fazer reload')
    // Vai fazer o reload do componente sem que o usuário perceba e sem que todo o site
    // faça reload
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.router.url]);
    });
  }


  isDesktopView(): Observable<boolean> {
    return this.isDesktop.asObservable().pipe(distinctUntilChanged());
  }

  getObservableWidth(): Observable<number> {
    return this.width.asObservable().pipe(distinctUntilChanged());
  }

}
