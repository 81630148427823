import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

// import { NavController } from '@ionic/angular';
import { UsuarioBlogService } from './usuario-blog.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardBlogService implements CanActivate {
  _proxRoute: string = '';

  constructor(
    private router: Router,
    private matSnack: MatSnackBar,
    private usuarioBlogSrv: UsuarioBlogService,
  ) { }

  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const _roles = next.data['roles'] as Array<string>;
    if (_roles[0] == 'livre') {
      return true;
    }
    // console.log('continuou')
    let _tpLogin = '';
    if (localStorage.getItem('PasseioJaBlog:token')
      && this.usuarioBlogSrv.tpUsuario) {
      if (this.usuarioBlogSrv.tpUsuario == 3) {
        _tpLogin = 'admin';
      } else {
        if (this.usuarioBlogSrv.tpUsuario == 1) {
          _tpLogin = 'guia';
        } else {
          if (this.usuarioBlogSrv.tpUsuario == 2) {
            _tpLogin = 'cliente';
          }
        }
      }
    }
    if (_tpLogin == '') {
      // salva o link para onde ia, para ser direcionado após o login
      this.matSnack.open('Necessário Login', undefined, { duration: 3000 });
      return this.router.navigateByUrl('');
    }
    if (_roles.indexOf(_tpLogin) < 0) {
      this.matSnack.open(`Tipo de Login não permite o acesso solicitado - rota ${state.url}`, undefined, { duration: 3000 });
      return this.router.navigateByUrl('');
    }
    return true;
  }

}
