import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { ConstantsBlog } from 'src/app/modules/shared2/constants-blog';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FuncoesGeraisBlogService {
  private dsTituloPagina = new BehaviorSubject<string>('');
  private flPaginaTemRetorno = new BehaviorSubject<boolean>(false);
  private flTemCabecPadrao = new BehaviorSubject<boolean>(true);
  _version: string = '';

  constructor() { }

  // Função chamada na inicialização do APP para obter a versão do APP do config.xml
  // e guardar na variável _version para uso a qq momento
  async SetAppVersion() {
    if (this._version == ''
      || this._version < environment.version) {
      this._version = environment.version;
    }
  }

  // Retorna a versão do aplicativo obtida na rotina acima (oriunda do config.xml)
  GetAppVersion(): string {
    return this._version;
  }

  // flTemCabecPadrao indica se o cabeçalho é a toolbar azul
  // (caso negativo será uma foto definida na propria pagina)
  setPagina(dsTitulo: string, flTemRetorno: boolean, flTemCabecPadrao: boolean) {
    this.dsTituloPagina.next(dsTitulo);
    this.flPaginaTemRetorno.next(flTemRetorno);
    this.flTemCabecPadrao.next(flTemCabecPadrao);
  }

  getTituloPaginaAtual(): Observable<string> {
    return this.dsTituloPagina.asObservable().pipe(distinctUntilChanged());
  }

  getPaginaTemRetorno(): Observable<boolean> {
    return this.flPaginaTemRetorno.asObservable().pipe(distinctUntilChanged());
  }

  getTemCabecPadrao(): Observable<boolean> {
    return this.flTemCabecPadrao.asObservable().pipe(distinctUntilChanged());
  }

  public async MostraErros(retorno: any, textoUnico?: string) {
    let _msgErroHTML: string = '';
    if (textoUnico) {
      _msgErroHTML = `<p>${textoUnico}</p>`;
    } else {
      if (retorno) {
        // console.log(_erros)
        _msgErroHTML = '<ul style="padding-left: 0px; padding-right: 0px;">';
        if (retorno.error && retorno.error.error && Array.isArray(retorno.error.error)) {
          let _erros = retorno.error.error;
          for (var i = 0; i < _erros.length; i++) {
            _msgErroHTML += `<li style="text-align: left; padding-left: 0px; padding-right: 0px;">${_erros[i].message ? _erros[i].message : _erros[i]}</li>`;
          }
          // _erros.forEach(element => {
          //   // console.log(element)
          //   _msgErroHTML += `<li style="text-align: left; padding-left: 0px; padding-right: 0px;">${element.message || element}</li>`;
          // });
          _msgErroHTML += '</ul>';
        } else {
          // o retorno da API não vem como array quando ocorre um erro diferente
          // de status 400 (retornado pela aplicação)
          if (retorno.error && retorno.error.message) {
            _msgErroHTML = `<p>${retorno.error.message}</p>`
          } else {
            // console.log(retorno.error)
            _msgErroHTML = `<p>Ocorreu um problema no acesso ao servidor. Favor tentar novamente.</p>`
          }
        }
      }
    }
    const options: any = {
      ...ConstantsBlog.ok_swal_options,
      title: '<p style="color: red">Atenção</p>',
      html: _msgErroHTML
    };
    await Swal.fire(options).then((result) => {
      return;
    });
  }

  FormatarHTML(strHTML: string): string {
    var div = document.createElement('div');
    div.innerHTML = strHTML.trim();
    return this.IndentarTags(div, 0).innerHTML;
  }

  private IndentarTags(node: HTMLDivElement | Element, level: number) {
    var indentBefore = new Array(level++ + 1).join('  '),
      indentAfter = new Array(level - 1).join('  '),
      textNode;
    for (var i = 0; i < node.children.length; i++) {
      textNode = document.createTextNode('\n' + indentBefore);
      node.insertBefore(textNode, node.children[i]);
      this.IndentarTags(node.children[i], level);
      if (node.lastElementChild == node.children[i]) {
        textNode = document.createTextNode('\n' + indentAfter);
        node.appendChild(textNode);
      }
    }
    return node;
  }

  TrimmedHtml(html: string): string {
    return html.replace(/^\s+/mg, "");
  }


  async ReclassificaArrayString(arrayString: string[]): Promise<string[]> {
    var _sortedArray: string[] = arrayString.sort((n1, n2) => {
      if (n1 < n2) {
        return -1;
      }
      if (n1 > n2) {
        return 1;
      }
      return 0;
    });
    return _sortedArray;
  }

  // formata como DD/MM/AAAA
  FormataDataDD_MM_YYYY(dtYYYYMMDD: string): string {
    const dtDD_MM_YYYY = dtYYYYMMDD.substring(6, 8) + '/' +
      dtYYYYMMDD.substring(4, 6) + '/' + dtYYYYMMDD.substring(0, 4);
    return dtDD_MM_YYYY;
  }

  // formata como DD/MM/AAAA com entrada como YYYY_MM_DD
  FormataDataYYYY_MM_DD(dtYYYY_MM_DD: string): string {
    const dtDD_MM_YYYY = dtYYYY_MM_DD.substring(8, 10) + '/' +
      dtYYYY_MM_DD.substring(5, 7) + '/' + dtYYYY_MM_DD.substring(0, 4);
    return dtDD_MM_YYYY;
  }

  InverteData(dtDD_MM_YYYY: string): string {
    const dtYYYYMMDD: string = dtDD_MM_YYYY.substring(6, 10) +
      dtDD_MM_YYYY.substring(3, 5) + dtDD_MM_YYYY.substring(0, 2);
    return dtYYYYMMDD;
  }

  FormataValor(valor: number, cdMoeda?: string): string {
    if (!cdMoeda) {
      cdMoeda = 'BRL';
    }
    const _arrayMoeda = ConstantsBlog.arrayMoeda;
    let _valorFormatado: string;
    for (var n = 0; n < _arrayMoeda.length; n++) {
      if (_arrayMoeda[n].sigla == cdMoeda) {
        let vlStr = valor.toString();
        let pedacos = vlStr.split('.')
        if (pedacos.length == 2) {
          _valorFormatado = _arrayMoeda[n].simbolo + pedacos[0] +
            _arrayMoeda[n].decimal + pedacos[1].padEnd(2, '0');
        } else {
          if (pedacos.length == 1) {
            _valorFormatado = _arrayMoeda[n].simbolo + pedacos[0] +
              _arrayMoeda[n].decimal + '00';
          }
        }
      }
    }
    return _valorFormatado;
  }

  // formato = 'CPF' ou 'RG'
  InserirMascara(value: string, formato: string): string {
    if (value && formato) {
      if (formato == 'CPF') {
        let valorFormatado = value + '';
        valorFormatado = valorFormatado
          .padStart(11, '0')                  // item 1
          .substring(0, 11)                      // item 2
          .replace(/[^0-9]/, '')              // item 3
          .replace(                           // item 4
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            '$1.$2.$3-$4'
          );
        return valorFormatado;
      }
      if (formato == 'RG') {
        let valorFormatado = value + '';
        valorFormatado = valorFormatado
          .padStart(9, '0')                  // item 1
          .substring(0, 9)                      // item 2
          .replace(/[^0-9]/, '')              // item 3
          .replace(                           // item 4
            /(\d{2})(\d{3})(\d{3})(\d{1})/,
            '$1.$2.$3-$4'
          );
        return valorFormatado;
      }
      console.log('Erro - InserirMascara com formato não previsto', { value, formato });
      return value;
    } else {
      console.log('Erro - InserirMascara com parâmetros incompletos', { value, formato });
      return value;
    }
  }


  // Formata o link para chamada do google maps estático
  FormatarUrlMapa(lat: number, lng: number): string {
    var staticMapUrl = "https://maps.googleapis.com/maps/api/staticmap";
    // Exemplo de link completo:
    //   `https://maps.googleapis.com/maps/api/staticmap?center=23.03,72.58&zoom=6&size=640x400&path=weight:3|color:blue|enc:aofcFz_bhVJ[n@ZpAp@t@b@uA%60FuAzEoCdJiDpLs@VM@y@s@oBcBkAw@cCoAuBu@eEaAiAa@iAi@w@a@o@g@g@k@e@u@uAaCc@i@w@y@eAo@i@UaBc@kAGo@@]JyKA}EC{G?q@?IGKCeGA{CAyCAyEAwEBaFAkJ?yGEyAIiLAiB?{@BcBJ}@@aBGwBEo@A@j@BjBFTHjEl@fOD%60C?|@RARAJERWPL@FE^S%60AI%60A&key=${environment.apiKeyGoogleMaps}`

    //Set the Google Map Center.
    staticMapUrl += `?center=${lat},${lng}`;
    //Set the Google Map Size.
    // staticMapUrl += "&size=400x300&scale=1";
    staticMapUrl += "&size=500x370&scale=1";
    //Set the Google Map Type.
    staticMapUrl += "&maptype=roadmap";
    //Set the Google Map Zoom.
    staticMapUrl += "&zoom=15";
    //Loop and add Markers.
    staticMapUrl += `&markers=${lat},${lng}`;
    staticMapUrl += `&key=${environment.apiKeyGoogleMaps}`
    // console.log(staticMapUrl);
    return staticMapUrl;
  }


  // Copia texto para clipboard (texto, link, json.stringfy,...)
  async CopiarParaClipboard(texto: string): Promise<string> {
    if (!navigator.clipboard) {
      // Se não tem a nova clipboard API eh pq o browser precisa usar a rotina antiga - que foi deprecated
      // - mas que ainda funciona em alguns browsers antigos
      let textarea = null;
      textarea = document.createElement("textarea");
      textarea.style.height = "0px";
      textarea.style.left = "-100px";
      textarea.style.opacity = "0";
      textarea.style.position = "fixed";
      textarea.style.top = "-100px";
      textarea.style.width = "0px";
      textarea.id = "textarea";
      document.body.appendChild(textarea);
      // Set and select the value (creating an active Selection range).
      textarea.value = texto;
      textarea.select();
      let successful = document.execCommand("copy");
      if (successful) {
        if (textarea && textarea.parentNode) {
          textarea.parentNode.removeChild(textarea);
        }
        return 'ok';
      } else {
        if (textarea && textarea.parentNode) {
          textarea.parentNode.removeChild(textarea);
        }
        return 'erro';
      }
    } else {    // Rotina para browsers novos - usando a clipboard API
      return new Promise(async (resolve) => {
        const aux = navigator.clipboard.writeText(texto).then(
          function () {
            resolve('ok');
          })
          .catch(
            function (error) {
              console.log('Erro no CopiarParaClipboard - novos browsers - ', error)
              resolve('erro');
            });
      });
    }
  }


  GenerateUniqueHash(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
  }

}
