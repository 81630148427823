import * as CryptoJS from "crypto-js";

import { IResultHttpBlog } from '../interfaces/IResultHttpBlog';
import { environment } from '../../environments/environment';
import { ConstantsBlog } from 'src/app/modules/shared2/constants-blog';
import { HttpBlogService } from './http-blog.service';

export abstract class BaseBlogService<T> {

  urlBase: string = '';

  constructor(
    public url: string,
    public loading: boolean,
    public http: HttpBlogService
  ) {
    this.urlBase = `${environment.url_api}/${this.url}`;
  }

  public GetAll(flNaoUsarSpinner?: number): Promise<IResultHttpBlog> {
    // this.loading = true;
    let _getAll = this.http.get(this.urlBase, flNaoUsarSpinner ? flNaoUsarSpinner : null);
    this.loading = false;
    return _getAll;
  }

  public GetById(uid: string, flNaoUsarSpinner?: number): Promise<IResultHttpBlog> {
    // this.loading = true;
    let _getById = this.http.get(`${this.urlBase}/${uid}`, flNaoUsarSpinner ? flNaoUsarSpinner : null);
    this.loading = false;
    return _getById;
  }

  async GetQuery(query: string, flNaoUsarSpinner?: number): Promise<IResultHttpBlog> {
    // Troca caracter "%" por "MMM" para não dar problema na rota
    query = query.replace(/%/g, 'MMM');
     // criptografa a query
    let _queryCript = await this.Criptografar(query)
    if (_queryCript.indexOf("%") > -1) {
      // se tem caracter que não vai funcionar, "%" por exemplo, não usa a query criptografada
      return this.http.get(`${this.urlBase}/query/${query}`, flNaoUsarSpinner ? flNaoUsarSpinner : null);
    } else {
      // manda a query com o "cript" no inicio
      return this.http.get(`${this.urlBase}/query/cript${_queryCript}`, flNaoUsarSpinner ? flNaoUsarSpinner : null);
    }
  }

  public post(model: T): Promise<IResultHttpBlog> {
    // this.loading = true;
    let _post = this.http.post(this.urlBase, model);
    this.loading = false;
    return _post;
  }

  public delete(uid: string): Promise<IResultHttpBlog> {
    // this.loading = true;
    let _delete = this.http.delete(`${this.urlBase}/${uid}`);
    this.loading = false;
    return _delete;
  }

    // Função para criptografar via cripto-js (permite decriptografia) - usada para trafego client/server
    Criptografar(text: string): string {
      let encrypted;
      try {
        let encrypted = CryptoJS.AES.encrypt(text, ConstantsBlog.secretyKeyCriptoJs.trim()).toString();
        let b64 = CryptoJS.enc.Base64.parse(encrypted);
        encrypted = b64.toString(CryptoJS.enc.Hex);
        return encrypted;
      } catch (e) {
        console.log('Error e)ncrypting data: ' + e)
        return text;
      }
    }

    // // Função para decriptografar via cripto-js
    // Decriptografar(encryptedData: string): string {
    //   let data;
    //   try {
    //     let b64 = CryptoJS.enc.Hex.parse(encryptedData);
    //     let bytes = b64.toString(CryptoJS.enc.Base64);
    //     data = CryptoJS.AES.decrypt(bytes, Constants.secretyKeyCriptoJs.trim());
    //     data = data.toString(CryptoJS.enc.Utf8);
    //     return data;
    //   } catch (e) {
    //     console.log('Error decrypting data: ' + e)
    //     return encryptedData;
    //   }
    // }

}
