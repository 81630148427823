import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { UsuarioBlogModel } from '../models/usuarioBlogModel';
import { BaseBlogService } from './base-blog.service';
import { HttpBlogService } from './http-blog.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioBlogService extends BaseBlogService<UsuarioBlogModel> {
  public token: string = '';
  public usuarioUid: string = '';
  public tpUsuario: number = null;
  public usuario: UsuarioBlogModel = new UsuarioBlogModel();
  private nmUsuarioSubject = new BehaviorSubject<string>('');
  private dlFotoUsuarioSubject = new BehaviorSubject<string>('');

  constructor(public http: HttpBlogService) {
    super('usuario', true, http);
  }

  publicarNovoNome(nmUsuario: string) {
    this.nmUsuarioSubject.next(nmUsuario);
  }

  publicarNovaFoto(dlFotoUsuario: string) {
    this.dlFotoUsuarioSubject.next(dlFotoUsuario);
  }

  observableNomeUsuarioLogged(): Observable<string> {
    return this.nmUsuarioSubject.asObservable().pipe(distinctUntilChanged());
  }

  observableFotoUsuarioLogged(): Observable<string> {
    return this.dlFotoUsuarioSubject.asObservable().pipe(distinctUntilChanged());
  }

}

