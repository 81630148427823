import { ErrorHandler, NgModule } from '@angular/core';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ScullyLibModule } from '@scullyio/ng-lib';

// import { CoreModule } from './modules/core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from 'src/environments/environment';

import { Shared1Module } from './modules/shared1/shared1.module';
import { GlobalErrorHandler } from './providers/globalErrorHandler';

@NgModule({
  declarations: [
    AppComponent
  ],

  imports: [
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ScullyLibModule
      .forRoot({
        useTransferState: true,
        alwaysMonitor: true,
        manualIdle: true,
      }),

    // CoreModule,       // libraries que soh podem ser importadas uma vez
    Shared1Module,    // angular material usados no app.component e no institucional
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],

  exports: [],

  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },   // interceptar problema com o service-worker
    {
      provide: SwRegistrationOptions,       // para o service-worker (obter atualização automatica de versão)
      useFactory: () => ({ enabled: environment.production }),
    }
  ],

  bootstrap: [AppComponent],

})

export class AppModule { }
