// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // Voltei a usar esta configuração, pois no macOs não consegue pegar do config.xml
  version: '0.01.10',

  url_api: 'https://stageapi.passeioja.com.br',
  // url_api: 'http://192.168.1.105:3000',

  urlPasseioJa: 'https://stagepwa.passeioja.com.br',
  // urlPasseioJa: 'http://192.168.1.105:8100',

  urlSiteGeren: 'https://stageempresas.passeioja.com.br',
  // urlSiteGeren: 'http://localhost:4200',

  urlSiteBlog: 'https://stageblog.passeioja.com.br',
  // urlSiteBlog: 'http://192.168.1.105:4200',

  urlLogo: 'assets/PasseioJaLogo.jpg',

  // dados do usuario ref. ao suporte do passeioJa criado no BD - que vai responder os chats
  // uidSuportePasseioJa: '33a12ca0-a27b-4851-86d9-5d43d44ad4c1',
  uidSuportePasseioJa: '00000000-aaaa-0000-aaaa-000011112222',
  // edEmailSuportePasseioJa: 'adm1@gmail.com.br',
  edEmailSuportePasseioJa: 'suporte@passeioja.com.br',
  // password fixa para todos user do Firebase
  passUser: 'PasseioJa123',

  // GoogleMaps API KEY
  apiKeyGoogleMaps: 'AIzaSyDHTHd6BK2EDXwkLqOSx829sJCm8eY7Xq0',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
