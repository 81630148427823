<header class="header  mat-elevation-z2">
  <div>
    <mat-toolbar class="passeioja_toptoolbar" style="background: white !important; width: 100% !important;"
      *ngIf="isDesktop">
      <section style="width: 100%;">
        <button mat-icon-button tabindex="0" class="botaoLogo" col-13 aria-label="Home" routerLink="/">
          <img src="../assets/PasseioJaLogo.jpg" class="logo" width="70" height="70" alt="logotipo" title="Lista posts">
        </button>
        <span class="spacer"></span>
        <div class="frase full-width" col-33>
          <!-- <div class="fraseTopo">Não somos uma agência de viagens</div>
          <div class="fraseTopo">somos MUITAS!</div> -->
          <div class="fraseTopo">Seus passeios e excursões</div>
          <div class="fraseTopo">em uma única plataforma!</div>
        </div>
        <span class="spacer"></span>
        <span class="botoesHeader full-width" col-50>
          <button mat-button tabindex="0" *ngIf="_tpUsuario != 3" style="float: right;"
            (click)="Navegar(_arrayMenu[3].url, _arrayMenu[3].option)"
            (keyup.enter)="Navegar(_arrayMenu[3].url, _arrayMenu[3].option)" color="primary">Como Funciona
          </button>
          <button mat-button tabindex="0" *ngIf="_tpUsuario == 3" style="float: right;" (click)="CriarPost()"
            (keyup.enter)="CriarPost()" color="primary">
            <img width="24px" height="24px" src="../assets/custom-ion-icons/add_post.png" alt="Criar Post">
            <span style="padding-top: -3px; color: #092f8a;">
              Criar Post
            </span>
          </button>
          <button mat-button tabindex="0" style="float: right;" *ngIf="_nmUsuario != ''">
            <img mat-card-avatar *ngIf="_dlFotoUsuario" class="preview" src="{{ _urlApi }}/storage/{{ _dlFotoUsuario }}"
              alt="Avatar Usuário">
            <img mat-card-avatar *ngIf="!_dlFotoUsuario" class="preview"
              src="../assets/custom-ion-icons/profile_photo.jpg" alt="Avatar Usuário">
            <span style="padding-top: -3px; color: #092f8a;">
              {{_nmUsuario}}
            </span>
          </button>
          <button mat-button tabindex="0" style="float: right;" *ngIf="_nmUsuario == ''"
            (click)="Navegar(_arrayMenu[2].url, _arrayMenu[2].option)"
            (keyup.enter)="Navegar(_arrayMenu[2].url, _arrayMenu[2].option)" color="primary">Entrar
          </button>
          <button mat-button tabindex="0" style="float: right;"
            (click)="Navegar(_arrayMenu[1].url, _arrayMenu[1].option)"
            (keyup.enter)="Navegar(_arrayMenu[1].url, _arrayMenu[1].option)" color="primary">Seja nosso
            parceiro!
          </button>
          <button mat-button tabindex="0" style="float: right;"
            (click)="Navegar(_arrayMenu[0].url, _arrayMenu[0].option)"
            (keyup.enter)="Navegar(_arrayMenu[0].url, _arrayMenu[0].option)" color="primary">Passeios</button>
        </span>
      </section>
    </mat-toolbar>
    <mat-toolbar class="passeioja_toptoolbar" style="background: white !important; width: 100% !important;"
      *ngIf="!isDesktop">
      <section style="width: 100%;">
        <button mat-icon-button class="botaoLogo" col-13 aria-label="Home" routerLink="/">
          <img src="../assets/PasseioJaLogoHorizontal.png" class="logo" width="164" height="70" alt="logotipo">
        </button>
        <span class="spacer"></span>
        <div class="boxMenu">
          <button mat-flat-button class="menuButton" [matMenuTriggerFor]="menuPrincipal" *ngIf="_arrayMenu.length > 0">
            <img src="../assets/icon/menu.png" class="menu" width="48" height="48" alt="menu">
          </button>
        </div>
        <mat-menu #menuPrincipal="matMenu">
          <button mat-menu-item *ngFor="let item of _arrayMenu" (click)="Navegar(item.url, item.option)"
            (keyup.enter)="Navegar(item.url, item.option)">
            <span style="color: #092f8a; font-size: 15px; font-weight: bold;">{{item.label}}</span>
          </button>
        </mat-menu>

      </section>
    </mat-toolbar>
  </div>
  <div
    [ngClass]="{'glCabec': (!isDesktop && _dsTituloPagina.length <= 30) || (isDesktop && _dsTituloPagina.length <= 50), 'subCabecMaisHeight': (!isDesktop && _dsTituloPagina.length > 30) || _dsTituloPagina.length > 50 }"
    *ngIf="_flTemCabecPadrao">
    <mat-grid-list cols="12" [rowHeight]="_heightSubcabec">
      <mat-grid-tile [colspan]="1" rowspan="1" style="margin-top: -5px;">
        <div *ngIf="_flPaginaTemRetorno">
          <button mat-icon-button tabindex="0" (click)="Retornar()" aria-label="Retornar">
            <img style="padding-left: 5px; padding-right: 5px; width: 39px; height: 39px;"
              src="../assets/custom-ion-icons/keyboard_backspace_blue.png" alt="Retornar" />
          </button>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="11" rowspan="1" style="display: flex; align-items: center; width: 100%;">
        <h1
          [ngClass]="{'glTituloCabecGrid': (!isDesktop && _dsTituloPagina.length <= 30) || (isDesktop && _dsTituloPagina.length <= 50), 'glTituloCabecGrid subCabecH1Device': (!isDesktop && _dsTituloPagina.length > 30), 'glTituloCabecGrid subCabecH1MaisHeight': _dsTituloPagina.length > 50 }"
          style="padding-right: 10%; color: white;">
          {{ _dsTituloPagina }}
        </h1>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</header>
<ngx-spinner bdOpacity=0.9 bdColor="#71baf6" size="small" color="#092f8a" type="ball-8bits" [fullScreen]="false">
  <p style="color: #092f8a; font-size: 20px;"> Aguarde... </p>
</ngx-spinner>
<router-outlet class="glFundo"></router-outlet>
